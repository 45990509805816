$(function () {
    $.mask.definitions["~"] = "[78]";
    $("input[name=phone]").mask("~ (999) 999-9999");
    //$('input[name=phone]').mask("+7 (999) 999-99-99");

    if (screen.width >= 1050) {
        $(".nav-vertical li").hover(
            function () {
                $(this).addClass("selected");
            },
            function () {
                $(this).removeClass("selected");
            },
        );
    }

    $(".nav-vertical .catalog-aside-link").on("click", function () {
        $(this).parent().siblings().removeClass("selected");
        $(this).siblings(".in-wrap").addClass("active");
        $(this).parent().toggleClass("selected");
    });

    // whenever we hover over a menu item that has a submenu
    $("li.parent").on("mouseover", function () {
        var $menuItem = $(this),
            $submenuWrapper = $("> .in-wrap", $menuItem);

        var menuItemPos = $menuItem.position();

        $submenuWrapper.css({
            top: menuItemPos.top,
            left: menuItemPos.left + Math.round($menuItem.outerWidth() * 1.015),
            // left: menuItemPos.left + Math.round($menuItem.outerWidth() * 0.900)
        });
    });

    // Header menu show
    $(".catalog-main .catalog__product-info-text .catalog-product-link").on(
        "click",
        function () {
            $(this).siblings(".catalog-product-text").toggleClass("active");
        },
    );

    $(document).on("click", ".header-show", function (e) {
        e.stopPropagation();
        // e.preventDefault();
        $(".header-media").toggleClass("active");
        $(".catalog-aside").toggleClass("active");
        $("body").toggleClass("over");
    });

    $(".header__search-btn").on("click", function (e) {
        e.preventDefault();
        var inputValue = $("#search").val().trim();
        if (inputValue.length) {
            $(this).closest("form").submit();
        }
        $(".header__search-inp").toggleClass("active");
    });

    $(".header .header__nav-drop .dropdown-toggle").click(function () {
        window.location = $(this).attr("href");
    });

    // Header Catalog

    $(".header__catalog-link").on("click", function () {
        $(".catalog-aside").toggleClass("active");
        $("body").toggleClass("opened-menu");
        $(this).toggleClass("active");
    });

    $(document).on("click", function (event) {
        var isCatalogLink =
            $(event.target).closest(".header__catalog-link").length > 0;
        var isCatalogAside =
            $(event.target).closest(".catalog-aside").length > 0;

        if (!isCatalogLink && !isCatalogAside) {
            // $('.catalog-aside').removeClass('active');
            $("body").removeClass("opened-menu");
            $(".header__catalog-link").removeClass("active");
        }
    });

    // info-icon
    $(".info-icon").on("click", function () {
        $(this).toggleClass("active");
    });
    $(document).mouseup(function (e) {
        var div = $(".info-icon");
        if (!div.is(e.target) && div.has(e.target).length === 0) {
            div.removeClass("active");
        }
    });

    // link go to tech tab
    $(".anchor-tex").on("click", function (e) {
        e.preventDefault();
        $(".description-tabs .nav-link").removeClass("active");
        $(".description-tabs .tab-content .tab-pane").removeClass("active");
        $(".description-tabs #pills-character-tab").addClass("active");
        $(".description-tabs .tab-content #pills-character").addClass(
            "active show",
        );
    });
    // langar
    $(".anchor-tex").on("click", function (e) {
        e.preventDefault(); // Запрещаем переход по ссылке
        var target = $(this).attr("href"), // Берем значение из ссылки как цель id="second-screen"
            destination = $(target).offset().top; // Ищем верхнюю координату у нашей цели

        $("body,html").animate({ scrollTop: destination }, 300); // Плавно проматываем до цели
    });

    // // Item more show
    // $(".item-none").slice(4, 4).show();
    // $("body").on('click touchstart', '.catalog-sec-item-btn', function (e) {
    // 	e.preventDefault();
    // 	$(".item-none:hidden").slice(0, 2).slideDown();
    // 	if ($(".item-none:hidden").length == 0) {
    // 		$(".catalog-sec-item-btn").css('visibility', 'hidden');
    // 	}
    // 	// $('html,body').animate({
    // 	// 	scrollTop: $(this).offset().top
    // 	// }, 1000);
    // });
    // Item more show
    $("body").on("click touchstart", ".catalog-sec-item-btn", function (e) {
        e.preventDefault();
        $(this).css("visibility", "hidden");
        setTimeout(function () {
            $(".hidden-category").addClass("show");
        }, 500); // 1000 миллисекунд (1 секунда)
    });

    // Product like
    $(".like").on("click", function (e) {
        e.preventDefault();
        $(this).toggleClass("active");
    });
    // icon red active
    $(".icon-red").on("click", function (e) {
        // e.preventDefault();
        $(this).toggleClass("active");
    });

    // Filter menu show
    $(".catalog-filter-show").on("click", function (e) {
        e.preventDefault();
        $(".catalog-filter").toggleClass("active");
        $("body").toggleClass("over");
    });

    // Basket card count
    $(".addCount").click(function () {
        let resultCount = $(this).siblings(".resultCount");
        let count = parseInt(resultCount.text());
        count++;
        resultCount.text(count);
    });

    $(".removeCount").click(function () {
        let resultCount = $(this).siblings(".resultCount");
        let count = parseInt(resultCount.text());
        if (count > 0) {
            count--;
            resultCount.text(count);
        }
    });

    // Main slide
    try {
        var homeSwiper = new Swiper(".homeSwiper", {
            spaceBetween: 30,
            effect: "fade",
            loop: true,
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
            },
            on: {
                "after-init": function () {
                    // После инициализации слайдера
                    // Установите состояние кнопок в нужное значение
                    updateButtonState();
                    //   homeSwiper.update();
                },
            },
        });

        function updateButtonState() {
            var nextButton = document.querySelector(".swiper-button-next");
            var prevButton = document.querySelector(".swiper-button-prev");

            nextButton.setAttribute("aria-disabled", "false");
            prevButton.setAttribute("aria-disabled", "false");
        }
    } catch (error) {
        console.log("error", error);
    }

    //   try {
    // 	var swiper = new Swiper(".dataSwiper2", {
    // 	  loop: true,
    // 	  slidesPerView: 3,
    // 	  spaceBetween: 20,
    // 	  freeMode: true,
    // 	  watchSlidesProgress: false,
    // 	});
    // 	var swiper2 = new Swiper(".dataSwiper", {
    // 	  zoom: {
    // 		maxRatio: 3,
    // 	  },
    // 	  loop: true,
    // 	  slidesPerView: 1,
    // 	  navigation: {
    // 		nextEl: ".swiper-button-next",
    // 		prevEl: ".swiper-button-prev",
    // 	  },
    // 	  thumbs: {
    // 		swiper: swiper,
    // 	  },
    // 	});

    //   } catch (error) {
    // 	console.log("error", error);
    //   }

    // var swiper1 = new Swiper(".main-slide-slider", {
    // 	speed: 1000,
    // 	spaceBetween: 25,
    // 	loop: true,
    // 	pagination: {
    // 		el: ".swiper-pagination",
    // 		clickable: true
    // 	},
    // 	navigation: {
    // 		nextEl: ".swiper-button-next",
    // 		prevEl: ".swiper-button-prev",
    // 	},
    // });

    // Product slide
    var swiper2 = new Swiper(".product-new-slide", {
        slidesPerView: 4,
        spaceBetween: 25,
        pagination: {
            clickable: true,
        },
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        breakpoints: {
            300: {
                slidesPerView: 1.1,
                spaceBetween: 20,
                centeredSlides: true,
            },
            600: {
                slidesPerView: 2,
                spaceBetween: 25,
            },
            900: {
                slidesPerView: 3,
                spaceBetween: 30,
            },
            1200: {
                slidesPerView: 4,
                spaceBetween: 30,
            },
        },
    });

    // Product slide
    var swiper3 = new Swiper(".product-xit-slide", {
        slidesPerView: 4,
        spaceBetween: 25,
        pagination: {
            clickable: true,
        },
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        breakpoints: {
            300: {
                slidesPerView: 1.1,
                spaceBetween: 25,
                centeredSlides: true,
            },
            600: {
                slidesPerView: 2,
                spaceBetween: 25,
            },
            900: {
                slidesPerView: 3,
                spaceBetween: 30,
            },
            1200: {
                slidesPerView: 4,
                spaceBetween: 30,
            },
        },
    });

    // Product slide
    var swiper4 = new Swiper(".product-action-slide-index", {
        slidesPerView: 4,
        spaceBetween: 25,
        pagination: {
            clickable: true,
        },
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        breakpoints: {
            300: {
                slidesPerView: 1.1,
                spaceBetween: 25,
                centeredSlides: true,
            },
            600: {
                slidesPerView: 2,
                spaceBetween: 25,
            },
            900: {
                slidesPerView: 3,
                spaceBetween: 30,
            },
            1200: {
                slidesPerView: 4,
                spaceBetween: 30,
            },
        },
    });

    // Brend slide
    var swiper5 = new Swiper(".brend-slider", {
        slidesPerView: 6,
        spaceBetween: 25,
        loop: true,
        pagination: {
            clickable: true,
        },
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        breakpoints: {
            300: {
                slidesPerView: 1.5,
                spaceBetween: 25,
                centeredSlides: true,
            },
            500: {
                slidesPerView: 2,
                spaceBetween: 25,
            },
            900: {
                slidesPerView: 4,
                spaceBetween: 30,
            },
            1300: {
                slidesPerView: 6,
                spaceBetween: 30,
            },
        },
    });

    // Product mini card slide
    var swiper6 = new Swiper(".productMini-slide", {
        // slidesPerView: 4,
        spaceBetween: 25,
        loop: true,
        pagination: {
            clickable: true,
        },
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        breakpoints: {
            300: {
                slidesPerView: 1,
                spaceBetween: 20,
                centeredSlides: true,
            },
            500: {
                slidesPerView: 2,
                spaceBetween: 20,
            },
            900: {
                slidesPerView: 3,
                spaceBetween: 22,
            },
            1300: {
                slidesPerView: 4,
                spaceBetween: 30,
            },
        },
    });

    // Order slide
    var swiper7 = new Swiper(".product-action-slide", {
        slidesPerView: 4,
        spaceBetween: 25,
        loop: true,
        pagination: {
            clickable: true,
        },
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        breakpoints: {
            300: {
                slidesPerView: 1,
                spaceBetween: 25,
                centeredSlides: true,
            },
            600: {
                slidesPerView: 2,
                spaceBetween: 25,
            },
            900: {
                slidesPerView: 3,
                spaceBetween: 30,
            },
            1200: {
                slidesPerView: 4,
                spaceBetween: 30,
            },
        },
    });

    // Tovar slide

    var modalSlider = new Swiper(".dataSwiperModal", {
        loop: true,
        slidesPerView: 1,
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
    });

    var smallSlider = new Swiper(".dataSwiper2", {
        slidesPerView: 1,
        loop: true,
        spaceBetween: 10,
        thumbs: {
            swiper2: modalSlider,
        },
    });

    var bigSlider = new Swiper(".dataSwiper", {
        loop: true,
        slidesPerView: 1,
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        thumbs: {
            swiper: smallSlider,
            swiper2: modalSlider,
        },
    });

    bigSlider.on("slideChangeTransitionEnd", function () {
        // var activeIndex = bigSlider.activeIndex;
        var activeIndex = bigSlider.realIndex;
        // modalSlider.slideTo(activeIndex);
        modalSlider.slideTo(activeIndex, 0, false);
    });

    modalSlider.on("slideChangeTransitionEnd", function () {
        // var activeIndex = modalSlider.activeIndex;
        var activeIndex = modalSlider.realIndex;
        // bigSlider.slideTo(activeIndex);
        bigSlider.slideTo(activeIndex, 0, false);
    });

    // Descriptin slide
    var swiper10 = new Swiper(".product-description-slide", {
        slidesPerView: 4,
        spaceBetween: 25,
        pagination: {
            clickable: true,
        },
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        breakpoints: {
            300: {
                slidesPerView: 1,
                spaceBetween: 25,
                centeredSlides: true,
            },
            600: {
                slidesPerView: 2,
                spaceBetween: 25,
            },
            900: {
                slidesPerView: 3,
                spaceBetween: 30,
            },
            1200: {
                slidesPerView: 4,
                spaceBetween: 30,
            },
        },
    });

    // Article news slide
    var swiper11 = new Swiper(".article-news-slider", {
        slidesPerView: 1,
        spaceBetween: 30,
        pagination: {
            clickable: true,
        },
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
    });

    // Ip camera slide
    var swiper12 = new Swiper(".ip-camera-slider", {
        spaceBetween: 20,
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
        },
    });
});

// Catalog price input range
$(".range-input1 input").on("input", function () {
    const minVal = $(".range-input1 .min").val();
    const maxVal = $(".range-input1 .max").val();
    $('.range-price1 input[name="min"]').val(minVal);
    $('.range-price1 input[name="max"]').val(maxVal);
});

$(".range-input2 input").on("input", function () {
    const minVal = $(".range-input2 .min").val();
    const maxVal = $(".range-input2 .max").val();
    $('.range-price2 input[name="min"]').val(minVal);
    $('.range-price2 input[name="max"]').val(maxVal);
});
